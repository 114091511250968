// @file init and helper functions for command bar

import { captureException } from '@@/bits/error_tracker'
import { isAppUsing } from '@@/bits/flip'
import window from '@@/bits/global'
import type { User } from '@@/types'

// defines valid user properties for CommandBar
enum CommandBarUserPropertyType {
  HasCreatedSlideshowBefore = 'has_created_slideshow_before',
}

const COMMAND_BAR_ORG_ID = '88b7c3d1'
const ENABLED_PRODUCTS: Array<'nudges' | 'spotlight' | 'checklists' | 'help_hub'> = ['nudges']
const APP = 'mozart'
const MS_PER_DAY = 1000 * 60 * 60 * 24

async function initCommandBar(): Promise<void> {
  if (!isAppUsing('commandBar')) return

  if (window.CommandBar == null) {
    const commandbar = await import('commandbar')
    commandbar.init(COMMAND_BAR_ORG_ID)
  }
}

async function bootCommandBarWithUser(currentUser: Partial<User>): Promise<void> {
  if (!isAppUsing('commandBar')) return

  // We do not initialize the command bar if the user is not registered or if the user id is not present
  if (currentUser == null || currentUser.id == null || currentUser.registered_at == null) return

  await initCommandBar()

  let environment = process.env.NODE_ENV ?? 'development'

  if (window.location.hostname.includes('.padlet.io')) {
    environment = 'staging'
  }

  try {
    const userProperties = {
      id: currentUser.id.toString(),
      name: currentUser.name ?? '',
      email: currentUser.email ?? '',
      tenantId: currentUser.tenant_id?.toString() ?? '',
      role: currentUser.role ?? '',
      tenantType: currentUser.tenant_type ?? '',
      paying: currentUser.paying ?? false,
      registeredAt: currentUser.registered_at?.toLocaleString() ?? '',
      environment,
      daysSinceRegistration: -1,
      app: APP,
      lang: currentUser.lang ?? '',
    }
    if (currentUser.registered_at != null) {
      const daysSinceRegistration = Math.floor(
        (Date.now() - new Date(currentUser.registered_at).getTime()) / MS_PER_DAY,
      )
      userProperties.daysSinceRegistration = daysSinceRegistration
    }

    const userIdentifier = `${APP}_${environment}_${currentUser.id}`
    void window.CommandBar.boot(userIdentifier, userProperties, {
      products: ENABLED_PRODUCTS,
    })
  } catch (error) {
    captureException(error)
  }
}

function setUserProperty(userProperty: CommandBarUserPropertyType, value: any): void {
  if (!isAppUsing('commandBar') || window.CommandBar == null) return

  const userProperties = {
    [userProperty]: value,
  }

  void window.CommandBar.setUserProperties(userProperties)
}

function trackEvent(event: string, properties: Record<string, string>): void {
  if (!isAppUsing('commandBar') || window.CommandBar == null) return

  window.CommandBar.trackEvent(event, properties)
}

export { bootCommandBarWithUser, CommandBarUserPropertyType, initCommandBar, setUserProperty, trackEvent }
